import React, { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { Title } from "@clubofcode/ui";
import { Layout } from "../components/Layout";

const Custom404 = () => {
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => router.push("/", "/"), 3000);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      title="404 - Seite nicht gefunden"
      description="Diese Seite wurde nicht gefunden."
    >
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="h-full flex flex-col items-center justify-center">
        <Title as="h1" className="text-6xl font-bold mt-12">
          404
        </Title>
        <Title as="h2" className="text-xl sm:text-3xl" bold>
          Diese Seite wurde nicht gefunden.
        </Title>
        <p className="sm:text-lg text-green-500 text-center font-medium mt-8">
          Weiterleitung zur Startseite erfolgt...
        </p>
      </div>
    </Layout>
  );
};

export default Custom404;
